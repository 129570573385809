import { colours, coloursArray } from "../config/tagColors";

export const rgbToRgba = (rgbValue, alpha = 0.5) => {
    if (!rgbValue) return rgbValue;
    const newRGBA = rgbValue.replace(/rgb/i, 'rgba');
    return newRGBA.replace(/\)/i, `,${alpha})`);
};

export const assignTechnologyColors = (technologies) => {
    const newTechColors = {
        ...colours,
    };
    let count = 0;

    technologies.forEach((technology) => {
        if (!newTechColors[technology.tag]) {
            newTechColors[technology.tag] = coloursArray[count];
            count += 1;
        }
    });
    return newTechColors;
};

export const toTitleCase = (str) => {
    return str?.replace(/\b\w+/g, (word) => word?.charAt(0).toUpperCase() + word?.slice(1)) || str;
};
