import { createSlice } from '@reduxjs/toolkit';
import axios from '../core/axios/index';
import Api from '../core/config/apiUrls';
import { colours } from '../core/config/tagColors';
import { showToaster } from './toasterSlice';
import { getErrorMessage } from '../core/utils/errorHandling';
import { assignTechnologyColors } from '../core/utils/commonUtils';

interface UsersDetails {
    createdAt: string;
    id: string;
    isActive: boolean;
    tag: string;
    updatedAt: string;
}
interface User {
    name: string;
    id: string;
    yearsOfExperience: number;
    email: string;
    technologyIds: Array<UsersDetails>;
    status: string;
    comments: string;
}
interface DirectoryList {
    userData: Array<User>;
    filteredRows: Array<User>;
    searchString: string;
    sortOrder: 'asc';
    orderBy: 'name';
    filteredTechList: Array<User>;
    dirSkillColours: {};
    countryList: any;
    technologyList: any;
}

const initialState = {
    userData: [],
    filteredRows: [],
    searchString: '',
    sortOrder: 'asc',
    orderBy: 'name',
    filteredTechList: [],
    dirSkillColours: {},
    countryList: [],
    technologyList: [],
} as DirectoryList;

const directory = createSlice({
    name: 'directory',
    initialState,
    reducers: {
        setUserData(state, action) {
            state.userData = action.payload;
            state.filteredTechList = action.payload;
        },
        setOptions(state, action) {
            state.countryList = action.payload.countryList;
            state.technologyList = action.payload.technologyList;
        },
        directorySearch(state, action) {
            state.searchString = action.payload;
        },
        setFilteredRows(state, action) {
            state.filteredRows = action.payload;
        },
        setOrder(state, action) {
            state.sortOrder = action.payload;
        },
        setOrderBy(state, action) {
            state.orderBy = action.payload;
        },
        setDirSkillColours(state, action) {
            state.dirSkillColours = action.payload;
        },
    },
});

export const {
    directorySearch,
    setFilteredRows,
    setOrder,
    setOrderBy,
    setUserData,
    setOptions,
    setDirSkillColours,
} = directory.actions;
export default directory.reducer;

export const getDirectoryDetails = () => async (dispatch) => {
    try {
        const response: any = await axios.get(Api.externalResourceList);
        dispatch(setUserData(response.data));
    } catch (error) {
        dispatch(
            showToaster({
                message: getErrorMessage(error),
            }),
        );
        return error;
    }
};

export const getOptions = () => async (dispatch) => {
    try {
        const response: any = await axios.get(Api.externalResourceOptionsList);
        const responseColours = assignTechnologyColors(response.data.technologyList);
        dispatch(setOptions(response.data));
        dispatch(setDirSkillColours(responseColours));
    } catch (error) {
        dispatch(
            showToaster({
                message: getErrorMessage(error),
            }),
        );
        return error;
    }
};

export const addExternalUser = (data) => async (dispatch) => {
    try {
        const response: any = await axios.post(Api.externalResourceAdd, data);
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
        dispatch(getDirectoryDetails());
        dispatch(getOptions());
    } catch (error) {
        dispatch(
            showToaster({
                message: getErrorMessage(error),
            }),
        );
        return error;
    }
};

export const editExternalUser = (userId, data) => async (dispatch) => {
    try {
        const response: any = await axios.patch(`${Api.externalResourceEdit}/${userId}`, data);
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
        dispatch(getDirectoryDetails());
        dispatch(getOptions());
    } catch (error) {
        dispatch(
            showToaster({
                message: getErrorMessage(error),
            }),
        );
        return error;
    }
};

export const deleteExternalUser = (userId) => async (dispatch) => {
    try {
        const response: any = await axios.delete(`${Api.externalResourceDelete}/${userId}`);
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
        dispatch(getDirectoryDetails());
    } catch (error) {
        dispatch(
            showToaster({
                message: getErrorMessage(error),
            }),
        );
        return error;
    }
};

export const setFilterDirectory = (data, userData) => () => {
    const typeOfFilter = data[1];
    switch (typeOfFilter) {
        case 'technology': {
            const technologyToFilter = data[0];
            if (technologyToFilter) {
                const filteredUser: any[] = userData.filter((user: any) => {
                    const allMatchingTechnologies = user?.technologies?.filter((tech) => {
                        return technologyToFilter.includes(tech.tag);
                    });
                    return allMatchingTechnologies.length > 0;
                });
                return filteredUser;
            } else {
                return [];
            }
        }
        case 'status':
        case 'country':
        default:
            return userData;
    }
};
