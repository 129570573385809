import axios from '../core/axios/index';
import { createSlice, createAction } from '@reduxjs/toolkit';
import { showToaster } from './toasterSlice';
import { authStart, authFinished, authFail } from './authSlice';

const initialState = {
    projects: [],
    count: 0,
    updating: false,
    optionsData: {},
};

const addProject = createSlice({
    name: 'project',
    initialState,
    reducers: {
        projectsList(state, action) {
            state.projects = action.payload.data;
            state.count = action.payload.count;
        },
        projectsCrudStart(state) {
            state.updating = true;
        },
        projectsCrudEnd(state) {
            state.updating = false;
        },
        optionsData(state, action) {
            state.optionsData = action.payload;
        },
    },
});

export const { projectsList, projectsCrudStart, projectsCrudEnd, optionsData } = addProject.actions;
export default addProject.reducer;

export const addProjectfuntion = (data) => async (dispatch) => {
    const url = 'project';
    try {
        dispatch(authStart());
        dispatch(projectsCrudStart());
        const response = await axios.post(url, data);
        dispatch(projectsCrudEnd());
        dispatch(authFinished());
    } catch (error) {
        dispatch(projectsCrudEnd());
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: error.response?.data?.error?.message
                    ? error.response?.data?.error?.message
                    : error.response?.data?.message,
            }),
        );
    }
};

export const editProject = (url, data) => async (dispatch) => {
    try {
        dispatch(authStart());
        dispatch(projectsCrudStart());
        const response: any = await axios.patch(url, data);
        dispatch(projectsCrudEnd());
        dispatch(authFinished());
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
    } catch (error) {
        dispatch(projectsCrudEnd());
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: error.response?.data?.error?.message
                    ? error.response?.data?.error?.message
                    : error.response?.data?.message,
            }),
        );
    }
};

export const listProject = (url) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.get(url);
        dispatch(authFinished());
        const payload = {
            data: response.data,
            count: response.totalCount,
        };
        dispatch(projectsList(payload));
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: error.response?.data?.error?.message
                    ? error.response?.data?.error?.message
                    : error.response?.data?.message,
            }),
        );
        return error;
    }
};

export const deleteProject = (url, data) => async (dispatch) => {
    try {
        dispatch(authStart());
        dispatch(projectsCrudStart());
        const response: any = await axios.delete(url, { data });
        dispatch(authFinished());
        dispatch(projectsCrudEnd());
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
    } catch (error) {
        dispatch(authFail(error));
        dispatch(projectsCrudEnd());
        dispatch(
            showToaster({
                message: error.response?.data?.error?.message
                    ? error.response?.data?.error?.message
                    : error.response?.data?.message,
            }),
        );
        return error;
    }
};

export const getProjectDetails = (url) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response = await axios.get(url);
        dispatch(authFinished());

        response.data.feTechnologies = response.data.feTechnologies.map((tech) => {
            return { id: tech.technologyId.id, tag: tech.technologyId.technology };
        });

        response.data.beTechnologies = response.data.beTechnologies.map((tech) => {
            return { id: tech.technologyId.id, tag: tech.technologyId.technology };
        });

        response.data.features = response.data.features.map((feature) => {
            return { id: feature.featureId.id, tag: feature.featureId.feature };
        });

        response.data.tools = response.data.tools.map((tool) => {
            return { id: tool.toolId.id, tag: tool.toolId.tool };
        });

        let userData: any = [];
        // let tagData: any = [];
        response.data.resources.map((resp: any) => {
            const userDetail = {
                userId: resp.user.id,
                userType: resp.userType,
                userName: resp.user.fullName,
                id: resp.id,
                // userRole: [{ id: resp.id, tag: resp.userRole }],
                userRole: resp.userRole && resp.userRole.split(',').map((role) => ({ id: resp.id, tag: role })),
            };
            userData.push(userDetail);
        });
        // response.data.technology.map((tech: any) => {
        //     const tagDetail = {
        //         id: tech.tag.id,
        //         tag: tech.tag.tag,
        //     };
        //     tagData.push(tagDetail);
        // });

        response.data.resources = userData;
        response.data.startDate = response.data.startDate && response.data.startDate.slice(0, 10);

        // response.data.technology = tagData;
        return response.data;
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: error.response?.data?.error?.message
                    ? error.response?.data?.error?.message
                    : error.response?.data?.message,
            }),
        );
        return error;
    }
};

export const getOptionsData = (url) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.get(url);
        dispatch(authFinished());
        dispatch(optionsData(response.data));
    } catch (error) {
        console.log(error);
    }
};
