import axios from '../core/axios/index';
import { createSlice } from '@reduxjs/toolkit';
import { showToaster } from './toasterSlice';
import { authStart, authFinished, authFail } from './authSlice';
import Api from '../core/config/apiUrls';

interface BuddyBasicDetails {
    id: string;
    buddyName: string;
    newJoineeName: string;
    progress: string;
    trainingStatus: string;
    count: number;
}

interface Tasks {
    detail: string;
    done: boolean;
}

interface TaskCategory {
    id: string;
    heading: string;
    taskCount: number;
    checklist: Array<Tasks>;
}

interface BuddyDetailedView {
    id: string;
    buddyName: string;
    newJoineeName: string;
    progress: string;
    trainingStatus: string;
    categoryCount: number;
    taskList: Array<TaskCategory>;
}

interface BuddyDetails {
    buddyListing: Array<BuddyBasicDetails> | [];
    buddyDetails: BuddyDetailedView;
}

const initialState = {
    buddyListing: [],
    buddyDetails: {},
} as BuddyDetails;

const buddy = createSlice({
    name: 'buddy',
    initialState,
    reducers: {
        setBuddyList(state, action) {
            state.buddyListing = action.payload;
        },
        setBuddyDetails(state, action) {
            state.buddyDetails = action.payload;
        },
    },
});

export const { setBuddyList, setBuddyDetails } = buddy.actions;
export default buddy.reducer;

export const getLateralJoineeList = () => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.get(Api.buddyListAPI);
        dispatch(authFinished());
        dispatch(setBuddyList(response.data));
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: error?.response?.data?.error?.message
                    ? error?.response?.data?.error?.message
                    : error?.response?.data?.message || 'Error',
            }),
        );
    }
};

export const getBuddyChecklist = (userId) => async (dispatch) => {
    try {
        dispatch(authStart());
        const response: any = await axios.get(`${Api.buddyTaskDetailAPI}/${userId}`);
        dispatch(authFinished());
        dispatch(setBuddyDetails(response.data));
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: error?.response?.data?.error?.message
                    ? error?.response?.data?.error?.message
                    : error?.response?.data?.message || 'Error',
            }),
        );
    }
};

export const updateBuddyTask = ({ userId, taskId, status }) => async (dispatch, getState) => {
    try {
        dispatch(authStart());
        const response: any = await axios.patch(`${Api.buddyTaskDetailAPI}/${taskId}/${userId}`, { status });
        dispatch(authFinished());
        dispatch(
            showToaster({
                message: response.message,
                type: 'success',
            }),
        );
        dispatch(getBuddyChecklist(userId));
    } catch (error) {
        dispatch(authFail(error));
        dispatch(
            showToaster({
                message: error?.response?.data?.error?.message
                    ? error?.response?.data?.error?.message
                    : error?.response?.data?.message || 'Error',
            }),
        );
    }
};
