export const colours = {
    'React Native': '#8898C3',
    Angular: '#fdb447',
    React: '#83ddf5',
    Node: '#6fa56f',
    CSS: '#5db8c7',
    Vue: '#df66a3',
};

 export const defaultTagColour = '#df66a3';

export const defaultDirTag = 'rgb(51 51 51 / 40%)';

export const AVAILABILITY_STATUS_COLOURS = {
    Open: 'rgb(51 195 119)',
    Closed: 'rgb(241 26 12 / 77%)',
    "On Hold": 'rgb(255 152 0 / 50%)'
};

export const DESIGNATION_COLOR_MAP = {
    Architect: '#FFFF00',
    'Associate Architect': '#008000',
    'Associate Principal Engineer': '#008000',
    Engineer: '#EE82EE',
    'Lead Engineer': '#0000FF',
    'Senior Engineer': '#4B0082',
    'Software Engineer': 'red',
};

export const DASHBOARD_COLOURS = {
    Shadow: 'rgb(255, 190, 11)',
    Billable: 'rgb(6, 214, 160)',
    benched: {
        primary: 'rgb(230, 57, 70)',
        light: 'rgb(230, 57, 70, 0.3)',
    },
    Booked: 'rgb(214, 65, 6)',
    users: {
        primary: 'rgb(69, 73, 74)',
        light: 'rgb(69, 73, 74, 0.3)',
    },
};

export const CALENDAR_COLOURS = {
    veryLightGrey: '#ccc',
    lightGrey: '#d4d4d4',
    pureWhite: '#fff',
    speechBlue: '#3f51b5',
    black: '#333',
    almond: '#172b3b',
    errorRed: 'rgb(191, 49, 12)',
    red: 'FF0000',
    darkBlue: '#192D3E',
    linkBlue: '#00377D',
    tickGreen: '#1ca823',
    eventBlue: '#3174ad',
    highlightBlue: '#4badea',
    grey: '#7f7a7a',
    filterBlue: '#68BCF5',
    buttonBlue: '#61DAFB',
};

export const STATUS_COLOURS = {
    'On Hold': 'rgb(255 152 0 / 50%)',
    Recommended: 'rgb(125 211 89)',
    Rejected: 'rgb(228 38 73 / 50%)',
    Cancelled: 'rgb(219 61 200 / 50%)',
    Scheduled: 'rgb(97 218 251 / 50%)',
};

export const coloursArray =  ["#00ffc6f2", "#81a7f0", "#c7a7e0", "#68BCF5", "#698959","#bc800d", "#b17974", "#dc5448db", "#b4b171", "#71b48f" ];

export function getRandomColorHex() {
    let hex = "0123456789ABCDEF",
        color = "#";
    for (let i = 1; i <= 6; i++) {
      color += hex[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const trainingStatusColors = {
    'Open': '#e1e1e1',
    'In Progress': '#ffc863',
    Completed: '#96cf96',
};

export const progressColors = (value) => {
    switch (value) {
        case '0':
            return 'darkgrey';
        case '100':
            return 'green';
        default:
            return 'orange';
    }
};

export const utilizationFactorCategoryColor = {
    'High': '255,104,104',
    'Normal': '146,190,203',
    'Medium': '85,143,85',
    'Low': '205,219,88',
    'Very Low': '253,172,88',
};

export const getUtilizationColor = (value) => (value ? `rgb(${value})` : null);
